import { Map } from "mapbox-gl";

export class FocusControl {
  public _map: Map | undefined;
  public _container: HTMLElement;
  private _onclickHandler: GlobalEventHandlers["onclick"];

  constructor(options: {
    container: HTMLElement;
    onclick: GlobalEventHandlers["onclick"];
  }) {
    this._container = options.container;
    this._onclickHandler = options.onclick;
  }

  onAdd(map: Map) {
    this._map = map;
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    const button = this._container
      .firstElementChild as HTMLButtonElement | null;
    if (button) {
      button.onclick = this._onclickHandler;
    }
    return this._container;
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
    this._map = undefined;
  }
}
